import Vue from 'vue';
import Router from 'vue-router';
import configuration from '@/configuration';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/Login'
    },
    {
      path: '/Login',
      name: 'Login',
      props:  (route) => ({
        AuthenticationService: configuration.CompositionRoot.AuthenticationService(),
        CentralServiceAPI: configuration.CentralServiceAPI,
        Inactive: route.params && route.params.Reason === 'inactive'
      }),
      component: () => import('@/views/Login.vue')
    },
    {
      path: '/Downloads',
      name: 'Downloads',
      props: (route) => ({
        DownloadService: configuration.CompositionRoot.DownloadService(),
        ApplicationDownloadURL: configuration.ApplicationDownloadURL,
        CentralServerURL: configuration.CentralServiceAPI,
        Token: route.query.token,
        JSESSION: route.query.jesession
      }),
      component: () => import('@/views/Downloads.vue')
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
