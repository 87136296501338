import IAuthenticationService, { AuthenticationInfo, AuthenticationConfirmation } from './AuthenticationService.Types';
import BaseService from './BaseService';

export default class Service extends BaseService implements IAuthenticationService {
    constructor(api: string) {
        super(api);
    }

    public async MultiFactorAuthenticationInformation(bearer: string): Promise<AuthenticationInfo> {
        try {
            const raw = await this.Get({
                uri: '/xapi/mfa/verify',
                authorization: {
                    bearer
                },
                dataType: 'json'
            });
            return {
                success: true,
                JSESSION: raw.JSESSION,
                XNAT_CSRF: raw.XNAT_CSRF,
                authenticationType: raw.authenticationType
            };
        } catch {
            return {
                success: false
            };
        }
    }

    public async RequestOTP(bearer: string): Promise<any> {
        const raw = await this.Get({
            uri: '/xapi/data/access/manifest',
            authorization: {
                bearer
            },
            dataType: 'json'
        });
        return raw;
    }

    public async Login(csrf: string, JSESSION: string, otp: string, authType: string, bearer: string): Promise<AuthenticationConfirmation> {
        try {
            const raw = await this.Post({
                uri: `/xapi/mfa/verify?XNAT_CSRF=${csrf}`,
                dataType: 'json',
                contentType: 'application/json',
                authorization: {
                    bearer
                },
                data: {
                    authenticationType: authType,
                    token: otp
                },
                headers: {
                    JSESSION
                }
            });
            return {
                success: true,
                JSESSION: raw.JSESSION,
                XNAT_CSRF: raw.XNAT_CSRF
            };
        } catch {
            return {
                success: false
            };
        }
    }

    public async Logout(): Promise<boolean> {
        try {
            await this.SpecialLogout();
            return true;
        } catch {
            return false;
        }
    }
}
