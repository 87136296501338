import BaseService from './BaseService';
import IDownloadService, { Manifest, ApprovedData } from './DownloadService.Types';
import helpers from '@/helpers';

export default class Service extends BaseService implements IDownloadService {
    constructor(api: string) {
        super(api);
    }

    public async GetDownloadItems(bearer: string, JSESSION: string): Promise<Manifest> {
        try {
            const raw = await this.Get({
                uri: '/xapi/data/access/manifest',
                authorization: {
                    bearer
                },
                headers: {
                    JSESSION
                },
                dataType: 'json'
            });
            return {
                ApprovalId: raw.approval_id,
                ApprovedUser: {
                    Name: raw.approvedUser.name,
                    Email: raw.approvedUser.email,
                    Active: raw.approvedUser.active,
                },
                ApprovedData: raw.approved_data.map((ad: any) => ({
                    __ID: helpers.Hash(`${ad.host}_${ad.jwt_access_token}`),
                    Id: ad.host,
                    Name: ad.host,
                    Host: ad.host,
                    AccessToken: ad.jwt_access_token,
                    Expiration: new Date(raw.download_expiration_time),
                    Data: ad.data.map((d: any) => ({
                        ProjectId: d.project_id,
                        Subjects: d.subjects.map((s: any) => ({
                            SubjectId: s.subject_id,
                            SubjectLabel: s.subjectlabel,
                            Sessions: s.sessions.map((ss: any) => ({
                                SessionId: ss.sessionId,
                                SessionLabel: ss.sessionLabel,
                                Url: ss.url
                            }))
                        }))
                    })),
                    TotalFiles: ad.totalFileCount,
                    TotalFileSize: ad.totalFileSize,
                    NumberOfSubjects: ad.data.reduce((acc: number, cur: any) => { acc += cur.subjects.length; return acc; }, 0),
                    NumberOfSessions: ad.data.reduce((acc: any[], cur: any) =>  acc.concat(cur.subjects), []).reduce((acc1: number, cur1: any) => acc1 + cur1.sessions.length, 0),
                    BundleUrl: ad.bundleUrl
                }))
            };
        } catch {
            return {
                ApprovalId: '',
                ApprovedUser: {
                    Name: '',
                    Email: '',
                    Active: false
                },
                ApprovedData: []
            };
        }
    }

    public DownloadItem(item: ApprovedData, onStart: any, onFinish: any): void {
        if (onStart) {
            onStart();
        }
        fetch(item.BundleUrl, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${item.AccessToken}`,
                'Content-Type': 'application/octet-stream'
            })
        })
        .then((resp) => {
            return resp.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${item.Host.replace('https://', '')}-bundle.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            if (onFinish) {
                onFinish();
            }
        })
        .catch(() => {
            if (onFinish) {
                onFinish();
            }
        });
    }
}
