


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { isValid } from 'date-fns';
import $ from 'jquery';

@Component({
    name: 'data-table'
})
export default class DataTable extends Vue {
    @Prop({
        required: true
    })
    private items!: any[];

    private itemList: any[] = [];

    private get Items(): any[] {
        return this.itemList;
    }

    private set Items(value: any[]) {
        this.itemList = value;
    }

    @Watch('items')
    private UpdateItemList(): void {
        this.itemList = this.items
            .map((item, index) => ({
                item,
                index
            }));
    }
}
