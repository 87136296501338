const helpers = {
    CreateGuid: (): string => {  
        function _p8(s: boolean) {  
            const p = (Math.random().toString(16) + '000000000').substr(2, 8);  
            return s ? `${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
        }
        return `${_p8(false)}-${_p8(true)}-${_p8(true)}-${_p8(false)}`;  
    },

    Hash(value: string): number {
        let hash = 0;
        if (value.length === 0) {
            return hash;
        }
        for (let i = 0; i < value.length; i++) {
          const chr = value.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0;
        }
        return hash;
    }
};

export default helpers;
