import router from '@/router';

export interface IRequest {
    uri: string;
    data?: any;
    dataType?: string;
    contentType?: string;
    success?: JQueryPromiseCallback<any>;
    failure?: JQueryPromiseCallback<any>;
    pureJson?: boolean;
    authorization?: {
        username: string;
        password: string;
    }|{
        bearer: string;
    };
    statusCode?: any;
    headers?: any;
}

export default abstract class BaseService {
    protected apiEndpoint: string;
    
    constructor(api: string) {
        this.apiEndpoint = api;
    }
    
    // To prevent recursive logout on server fail
    protected async SpecialLogout(): Promise<void> {
        return $.ajax({
            url: `${this.apiEndpoint}/app/action/LogoutUser`,
            type: 'GET',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            }
        });
    }

    protected Get(request: IRequest): JQueryXHR {
        return this.Call(request, 'GET');
    }
    
    protected Post(request: IRequest): JQueryXHR {
        return this.Call(request, 'POST');
    }
    
    protected Put(request: IRequest): JQueryXHR {
        return this.Call(request, 'PUT');
    }
    
    protected Delete(request: IRequest): JQueryXHR {
        return this.Call(request, 'DELETE');
    }

    private Call(request: IRequest, type: string): JQueryXHR {
        const contentType: string = request.contentType === undefined ? 'application/x-www-form-urlencoded' : request.contentType;
        const dataType: string = request.dataType === undefined ? 'json' : request.dataType;
        const data = contentType === 'application/json' ? JSON.stringify(request.data) : request.data;
        const url = request.uri.startsWith('http') ? request.uri : `${this.apiEndpoint}${request.uri}`;

        const callArgs: any = {
            url,
            xhrFields: {
                withCredentials: true
            },
            beforeSend: (xhr: any) => {
                if (request.authorization !== undefined) {
                    const basicAuth = request.authorization as { username: string; password: string };
                    const bearerAuth = request.authorization as  { bearer: string };
                    if (basicAuth.username && basicAuth.password) {
                        xhr.setRequestHeader('Authorization', `Basic ${btoa(`${basicAuth.username}:${basicAuth.password}`)}`);
                    } else if (bearerAuth.bearer) {
                        xhr.setRequestHeader('Authorization', `Bearer ${bearerAuth.bearer}`);
                    }
                }
                if (request.headers) {
                    if (request.headers.JSESSION) {
                        xhr.setRequestHeader('JSESSIONID', request.headers.JSESSION);
                    }
                }
            },
            crossDomain: true,
            method: type,
            type,
            data,
            contentType,
            dataType
        };

        if (request.statusCode !== undefined) {
            callArgs.statusCode = request.statusCode;
        }

        const call = $.ajax(callArgs)
            .fail(async (reason) => {
                const notAuthorized: number = 401;
                if (reason.status === notAuthorized) {
                    router.push('/Login');
                    return;
                }
                // Check if the user session is still valid.
                // If it is not, return user to Login.
                return this.CheckSession()
                    .then((isValid) => {
                        if (!isValid) {
                            this.SpecialLogout();
                            router.push({ name: 'Login', params: { Reason: 'inactive' }});
                        }
                    });
            });
        
        if (request.success != null) {
            call.then(request.success);
        }
        
        if (request.failure != null) {
            call.fail(request.failure);
        }

        return call;
    }


    private async CheckSession(): Promise<boolean> {
        return new Promise<boolean>(() => true);
        return $.ajax({
            url: `${this.apiEndpoint}/data/JSESSION`,
            type: 'GET',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            }
        })
        .then((result) => {
            const jsessionid = localStorage.getItem('JSESSIONID');
            if (result === jsessionid) {
                return true;
            }
            return false;
        });
    }
}
