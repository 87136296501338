








import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import helpers from '@/helpers';

@Component({
    name: 'styled-checkbox'
})
export default class StyledCheckbox extends Vue {
    @Prop({
        default: false 
    })
    public disabled!: boolean;

    @Prop({
        default: false
    })
    public value!: boolean;

    @Prop()
    public context!: object;

    @Prop({
        default: ''
    })
    public name!: string;

    public get Checked(): boolean {
        if (!this.initialized) {
            this.initialized = true;
            const newValue = this.value.valueOf();
            if (newValue !== this.checked) {
                this.checked = newValue;
            }
        }
        return this.checked;
    }

    public set Checked(value: boolean) {
        this.checked = value;
        this.$emit('input', this.Checked);
        this.$emit('changed', this.Checked, this.context);
    }
    
    private initialized: boolean = false;

    private checked: boolean = false;

    private Id: string;

    constructor() {
        super();
        this.Id = helpers.CreateGuid();
    }

    @Watch('value')
    private OnChanged(): void {
        this.initialized = false;
    }
}
