import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import configuration from '@/configuration';
import DataTable from '@/components/DataTable.vue';
import StyledCheckbox from '@/components/StyledCheckbox.vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import vuescroll from 'vuescroll';

Vue.config.productionTip = false;

Vue.mixin({
  data: () => {
    return {
      get configuration() {
        return configuration;
      }
    };
  }
});

Vue.directive('observe-visibility', ObserveVisibility);
Vue.component('data-table', DataTable);
Vue.component('styled-checkbox', StyledCheckbox);

Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      sizeStrategy: 'number',
      detectResize: true
    },
    scrollPanel: {
      scrollingX: true
    },
    scrollButton: {
      enable: true,
      opacity: 0.5,
      background: '#22262d'
    },
    rail: {
      background: '#bfc2ca',
      specifyBorderRadius: '0',
      opacity: 0.2,
      size: '20px',
      gutterOfSide: '0'
    },
    bar: {
      background: '#22262d',
      size: '18px',
      opacity: 0.5,
      keepShow: true
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
